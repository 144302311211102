const initialState = {
  loading: false,
  totalSupply: "",
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        totalSupply: action.payload.totalSupply,
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };

    case "SET_DATA_LOADING_FALSE":
      return {
        ...initialState,
        loading: false
      }

    case "DISCONNECT_AND_CLEAN_DATA":
      return {
        loading: false,
        totalSupply: "",
        error: false,
        errorMsg: "",
      }

    default:
      return state;
  }
};

export default dataReducer;
