const images = [
   'images/sliderimages/1.png',
   'images/sliderimages/2.png',
   'images/sliderimages/3.png',
   'images/sliderimages/4.png',
   'images/sliderimages/5.png',
   'images/sliderimages/6.png',
   'images/sliderimages/7.png',
   'images/sliderimages/8.png',
   'images/sliderimages/9.png',
   'images/sliderimages/10.png',
   'images/sliderimages/11.png',
   'images/sliderimages/12.png',
   'images/sliderimages/13.png',
   'images/sliderimages/14.png',
   'images/sliderimages/15.png',
   'images/sliderimages/16.png',
];

export default images;
