const whitelist_addresses = [
 "0x988b2a936bD84b2c78A0eC234821A87AA64e6Aa6",
 "0x297cCdA8865d5e3604dFA227FDD6eacA3BE59c43",
 "0x99436B953e12C2E3aA329c75513A86148D6f1443",
 "0x4E2CC19317d23680AAfE52189491D12F890c3c02",
 "0xFB164E66f1BEd756B95621e3c4D3548a37348461",
 "0x5B07f88a5c6f1D20E6b23Bd0f7850e48f552dffE",
 "0x1e1F3676F36AcDB3626eFAD1d03fE4fFfBCc07EC",
 "0xe58678098a6eF40BA48Fb0EaBd2a2b10f4aD1c8b",
 "0x60d37888991479Be03f39E32c6B2e6ba121335d1",
 "0xC0b1E7646FDBfA2174cd10F41BD03D399d5CBb4c",
 "0xea9256149c6edE78EF457e293d9014703e7B4ccB",
 "0x5D125481F1f346b86F6a59429422713FA48bF502",
 "0xA0065d0A4dC340b9955783289726568A97F13760",
 "0xd376F87249f959555efD518129e29D4D792D4847",
 "0x8f159b9E583431f2D0E4D560B0E663eBC6037A96",
 "0xF133e0dF9bd84785361a27E1925175FE7EC99809",
 "0xF05D5c9eA307a9c939120718fA5b59e8A515c475",
 "0xf0A4Db1E64F81980EaFfFe35fB8c547bBCE0e540",
 "0x6C7357EbF39Aa9bBAbC6A869260a1EF1D93b5723",
 "0x181B4506Da5c10C379B7344057dA0B2eC763Adff",
 "0xBFee60d23c6B3C6e27611EF7E2A9ac803D07f9ba",
 "0xB65688e246B11C768A1620f47b327ED08b8b2b4F",
 "0xe0C0a67871cC836c6958acC09CD4a2488Efa1Cd8",
 "0x1D07BC93AF0B90ceF397548AdF4F53d261E476aD",
 "0x0302F3434dBdee9d3faF680cba8A2bAe6b34A83C",
 "0x20C467db9B9Fe0fA39D879b3f23c475582Da2Fba",
 "0xc5215ab188E1d84A7Ee9C33C078686DB1f7Dde41",
 "0xCFA98b01459d3f8782A6829E47309b2B5C5a72f1",
 "0x5EAE6c797ac561cb68Cd7a972963069122138157",
 "0x5ccf599B7d43Bc3F576EA01EC0aA307ecbc94102",
 "0x9E43d6ba519A2111C6B97F0d56208586264e6a75",
 "0xEE9afFa3dB30adBF7DB8030e2DedDd3dEE6B0Be5",
 "0x318FD082c6cD270438f10885Ce6F02D53b87E049",
 "0x6F85f16B35a6BCDe77Ff085634875CA608c826B4",
 "0x8449A07a3D8D6b029B289Dc3dC27f7cF43eCCEc2",
 "0xc4Afe4030e65d3f10AE20dafE822E206153156b5",
 "0xBEAa16aBD3A04b05f7E171eEa89114efbda62F6e",
 "0x9eA0C4BB61096ade0638E52bf0241deA5c2424aD",
 "0xE8A30a34Ba93dE71fCf77e2c4bb228E982976F42",
 "0x2f669b9ce84A4e19fba88Af7C135FA13C45A26fa",
 "0x0979667dBa025217d24187b959a186f05D4AcC37",
 "0x1ce2b79c37bb9815DC36e77b57d2f7407C43EFF8",
 "0xF7026D059f48cA14949388C4010157022e9075C5",
 "0x06F667cD96735F1850799BAa0917800E7d0bEB39",
 "0xBf4a60736dD30B807E176e97AE2fD551D0764eB2",
 "0x44F6Cf1a330b584f6f021CaE08c98e59107BB4a5",
 "0x73624873Cd686A4217c265554c57C817a1265368",
 "0x2560a3b55d9B043e9AEEE6998Ec7E222A078a383",
 "0xdCCE6db92D0A024c060A0CB3439CC8863d20e6B3",
 "0x205b993Bb19930C80FB10ddF4f4c423e49c4cAAC",
 "0x58A6aaC81EaF19DcA1297B021af7f871e2122ab4",
 "0x30F662D7b32Be999da54D82027c171261bc50e9d",
 "0x5D824ac4b69776f5F3C5961043D684FF6091fAa4",
 "0xCcc7f2EdF954454c238a421Fa896c26D09B7D078",
 "0x727A10Ae1afc91754d357b4748F6d793c9795026",
 "0xe1717d2eE514ad1933B4760EbF7551e19F5B9ff8",
 "0x97Db0E57b1C315a08cc889Ed405ADB100D7F137d",
 "0x3C6730546829D451Fa62dC214693002B4747f162",
 "0x7453395417538BB459870402A800acF88D293Df5",
 "0xF2b1ccE7198DF850d5B940900527a96920b0971c",
 "0x62e4992B1FAE059E01f9bD0B6aCc78f665c2a69F",
 "0x984c5d268B220784E87fbe8EDbb5C6b9F7ba9Fc4",
 "0x0FAb40a6e43eE85Dda2052642330f75c6d86f7a0",
 "0xfdC04c3A48fFA45434974e70E6C14ba17e375178",
 "0x89051Fc828DA7148Dd3FCAA3f14e6B3184fa017d",
 "0xcD1386E63e7fd1326651b8E1Eb7B119cC443D470",
 "0x073306Ef2e41E8d8Daa7e108d059B29675170a3F",
 "0x27e9531d81E0D89CE04394E233d406256d66d36a",
 "0x0F6b73e16C8cF8BAC1EA69b50e142c2edB8f1c96",
 "0x781F260089727F8151f6788Df627dA095B39bEeE",
 "0x0b9326Be62629e7bC50887D5ab122691C8A56A0b",
 "0xe7684f6fa3E6AFE5EAa08CeC157fa6E3E9AAfDf8",
 "0xe27D1dea24e9c7b8Cb8De9C5F480Ef20120874F3",
 "0x5bFaad4A04Ab0Fab6d206c9ea1144da886d31f9a",
 "0xC19Ff2964697fd734Ea4A1275374b349619913a9",
 "0xe98A9149718A6251Cd1e505b3c4c40b1C0E36885",
 "0x978452F8F19542633e4e0BBD3B96B0919Fe80202",
 "0x22267E2A740a5a7f0b055C1211B065d557a0280c",
 "0x2b5ad0341367cA7Be5B03506b804F0d2780D1581",
 "0x6211BDBDdc9fDCb972dcE6678a35b7bc5255BA41",
 "0x2B8D8b12E6Eb8aC4915189b05D7BbD44822BCa32",
 "0x4ebCe12FF36E8781B0c699EB92978ABcc4556cE2",
 "0xde583e18cfD40668FE102d366e87AA377ad5c3AA",
 "0x67D31A029242938C9CFD95D8f0b1596d8360c562",
 "0xe8284355C38425d3Ab4bEdD44AB03764B77E6105",
 "0x7c841e9C6Fcf2973894a76e2e9fe18428429FFac",
 "0xa8362003BB667bbFceF41BCBcd981Ed6E257A729",
 "0xAB263CAc1447D70b1dA461e57B986589369722cf",
 "0x38476bc1deb12F317cabC3F49fBBb80FbaB6f3ec",
 "0x6Ad563d748c2c84B1cd5eE52B71f9D0049861dF3",
 "0x22DF7b4019f321d868c32C57A7FfeebfcB05d2Cc",
 "0x8ea64cE37b7b43974905Df5Afe6FcFEFf424c0a9",
 "0x36C4C53bB6b0B8C59E98b35a1EABb5d43B5bC27F",
 "0x1d453454f2086A831562Deb1Ed8C6a2fc457F7a0",
 "0x85247035Ec747335eA039b3b45B8E9aF05659E1F",
 "0xBD0d87EfAd6eB6D9c6c8D7C9B7E80F37daBF19e5",
 "0x566cf9d582A5C448aa86D7f03511a4C3ab6eD5A3",
 "0xb76087F5D3966A9b2f23BE78F7A060d4bE060891",
 "0xfAe898910974a837f187946572A493b6F6E5A687",
 "0x61790F08a5c1F3642Dfb38fACaA51AfF84B82C12",
 "0x3205285E5bb4D1985D9F1782c36BA80b44Cae0ec",
 "0x982d4892fC417234DD5b42C12Bd841aD07110BD2",
 "0xe8Cc995D08E91fCe86ae28FF76d29E1d1475FFF8",
 "0x8a622Bc901de1fa2384d42FFA79606e446eD788F",
 "0x4498eA60ff585994D5AC75841EbBDdaEEaD1283C",
 "0xED8E924735F590572361b52657ABd9A3260F35a0",
 "0x2a15f1FEd563C9C815eB510dcb4A3475a087894d",
 "0x901359d158f206f86E12050F8579285147c3b82b",
 "0xD88CFF1D9F7e0E511Ca50f6f169C03354b384135",
 "0x8A0ba5E4063C1FFA294FA6Bd9dE937f1B5BD4600",
 "0xa2ADcD11e4269e4eBbe3B26c02D38D64Ec350137",
 "0xF72e075E2AdD0f59dc0691515F36aFf1C86B259C",
 "0xcA43BdFF72026c166a6fd7EaEea07e08Eb85D212",
 "0xe9daE671511eCBe1E8322B4d5fA96b4bE05CC3b4",
 "0xADacD79719DeC7DdEB3E85D30414785A699642F2",
 "0x8ed28AD8cED70535CFfb3F01008413a409C1f826",
 "0x72bb07490a94fEe5c202345bD52814f6d0790034",
 "0x10e0dcAF503feA95984427a2c851E531E7985c16",
 "0xA9545FAd80713E454513a721EF8d3fbd475E683f",
 "0xb094946Ae50BddcFe5509E38d51e10698b7Be010",
 "0x92d06DA4EdC46d438faA7c5d26f15C67758aB232",
 "0x9bCE3FCdd251a43EF7AA987F2d5Ebc0A29Ace7f1",
 "0x0aA4fe2a51a8CAa32997a7D933F8DdD7EbAf2422",
 "0x8834EBA50166a6402414b8FEF28b2d5Eba9650bE",
 "0xd876F90924B731fFeb29F3a70e09B01BCCafcdBa",
 "0x08Ee2D630B8bCf4fc8c59CE82bA4368e344255bE",
 "0xb0570af9657c5d0521994E17734C185F2040Af9C",
 "0x2c10D02F2879EDB2deDE3bF895B4532836455618",
 "0x376d1c8c8cd7dA04e5BF5aA6D97F62112b263930",
 "0x84f2Ec9e1b15fccBa757dA75DB1794D41f9B638E",
 "0xBBFa220d5a5c64c558374ee8722fBf2730056a8D",
 "0x1d5C7762b24D6926276dBFC272883a9Fc0eCa8F7",
 "0x2b1Fb4da7b0a300EC61105c537c7c658D371d2fD",
 "0x18e0f4E4D954Eb3498aAe0d990801f96b135b1b2",
 "0x72904d63B64C803fF63Ad92bE35b8f51fcCa9A59",
 "0xC55E66fe1623e56fF4fB42c10406632f843FC562",
 "0xa8019F80530439c2e28fb02996c2ED0247dA2436",
 "0x395AABf95e60e6ED588183311396b52d06C7b2aE",
 "0xAe625E7f70a36C1Db67BFD07158b91fbe17E8881",
 "0x965dC18dA3cf90410f4C271A21E608BE22a4d5B4",
 "0xCbbbE8b6CB1D0dE071728C36C29dB40918f98231",
 "0xB32E6c7189E6f8b1EbF54E1402837EADD7aE1e14",
 "0xd4ebcef876fB4E6c8bEFFAD44A937C6067096eBE",
 "0xA225Ee0fefe4516881578c6F79765dB1b807C8Ac",
 "0x6EFDe0eF76110897dAC0bE02E1092088dee38802",
 "0xf241E1c926236D8100A696EbE1b4793F06eAD712",
 "0xa33f2ecFea6c9D345D82d68fc7c1716507847979",
 "0xEAA1075bF36F569dD3Bccf676B49B3D0E6DEFcC3",
 "0x520A28Bd6960cD0D50B5DBEF6D3838C2c6Cdc4F7",
 "0x962A2880Eb188AB4C2Cfe9874247fCC60a243d13",
 "0xD36d98255ab815ae8E3995F164c0234c5C8c41a5",
 "0x5e8E2cb739CB27BF937bd63A1c92A4070294C140",
 "0xEde46d06a39e7a3B70B1A6492a95E6648c2fd6f5",
 "0x81D52F723C4dC2E6f96aC31d289abE1aC1673d46",
 "0x3be63ed41965e30Bd2fec57cae8B975A800548C4",
 "0x1716b464b5E3A770dF3D0AA54215fFC2CA8BC735",
 "0xD0FE971732E547059eA6009c79a2d6155bdb41a4",
 "0x7826729AAD6D8650c40e59Fc335B64BC55acB179",
 "0x940Cd3b3341F507Df5e84770Ee9db9613E70fC4d",
 "0x0Bff926237388495E68d58C1616F3D147Ff33dbD",
 "0xaae6e85f0c6bdC13F7257a1875c959a7D4C2BEec",
 "0x5fa6b95eCEbCf18d5c2336993F6CcF80AAbB03dB",
 "0x12D3d8d99084259d6EeD746C6197482aD6029281",
 "0x08948413fBFAac686cE9392da9E97060fB4c38f3",
 "0x5B187c84b31C243202959aB4B54f8396E5DBaf32",
 "0x65A6a51CAb9A23d0937416b23692327Ef7CF7934",
 "0xE55B9fEB4DfBC99eC0e82Ff9cDCAEf0D154Beb7C",
 "0x484d88b28069962499fFb57A6F9bCaeb5C528AD4",
 "0xf35D572b4528d05053aA60ec61C8e60d66FbA782",
 "0x8Cb6a4adF31dE6F661166909761d12E55De22Bcb",
 "0xEd42a25A7fDE348473e57DB6DB4B86893b7B845B",
 "0xb3abF9AE623A22EA952cA63f7D7E57ba1059ec2e",
 "0x6D563083718ec8cA23937DEd5356a96D3c6B4CA3",
 "0x7d8cc24C99A93949BAb7f9b1344c9621F4EFa538",
 "0x9a8bDBE5Bb0b63312e5AF863aA66dcbC9D7EC4FD",
 "0xcb58e5B910D260Aa1E6cF55FECC5261868ccA570",
 "0x43fFCdaaA502CD869B40d7A9fA3B2297EEa9B405",
 "0x8f746ab72C83Fd74522A0C40dCCb6277834648E8",
 "0x47383594926D448BC76c958C2B33F691D8F5a60f",
 "0x7fd3B1F0e3Bee1466F53429AE343051c1D187eb4",
 "0xDc9FB6c1Ca9a6F584aa24b559feF198cdD842696",
 "0x34385451e6a67d083C27F5bFBD59b60152F02272",
 "0xbad57f44Bf1d56eE19396c600E5496D7A407D58B",
 "0xaBE4F0F706D3E612ffF3C56fB255D8265cAcB9ac",
 "0x5A85FA7e5738BaEA6B330247c43A2B2e3b93659C",
 "0x1A76d029920A6A298Bb66D7f74caE7B3596c4674",
 "0x7C0FFF7fA503f91b423FA145283c89F49FB91Bf3",
 "0xcd2ffAf0Bf833EeF3b09C22756A52701F035844a",
 "0x8b98edaad83C9D2302834F868073A52Dd18585c6",
 "0x378d3FCf37b21e49Cc68FFAD092ecE03ee2C906f",
 "0x202329BA5f0F6D8b789d2F816D7f6E9b2ee18617",
 "0x82cEA20bC183EEAb87705893161fD91b5ae18CFC",
 "0x42c1fB87419E4034dACF1231f88aa74b25F723b4",
 "0x41E168C9aa29661f958876209c6c3545De4CFd5f",
 "0xc4436415e2fe276B809dA2741606dE50168be488",
 "0x4130a43C7eefCa02Dce917715B889F9EeC01D2E8",
 "0x95D6326507DCA1ACE74EF6ca516F4dB97893d5E9",
 "0x015c5fBC1F31ad795e4B264EBb7C71fc647a44ac",
 "0x3f129878D8BBEF25EB3aE394B909aADf5142206E",
 "0x7903d1053EbDBE1f50783752F3B0d834213a799a",
 "0xbE88D5297338De35AcE2562523503bEE17e82808",
 "0x790192Ff7c4b47d88698107a0B5aD4eCdB009425",
 "0x07a3ab01FA673A13d57553745dc39B06bF706A7A",
 "0x4013BaEa632f904851de2D8AF9b5206Fe98b145D",
 "0xE4f3d4685e9149F24A89245a3513e3b8b896f51a",
 "0xE89280E70723652e0bD2cb276De3711a7e272b38",
 "0xBB6Ba0F7195d4014eF21c2c8aC8568c390693374",
 "0x330AD0d38e9c12cbC34d7cfFEFCd99E5530b6bfb",
 "0xF272bA9A2584af2943ec2f72394926449FD12eD4",
 "0xD78352667839e0DE1973560948C06A8Ca1aee4f7",
 "0xee72B38f98Bd65811e26b2D239ec94d30C1F9457",
 "0x5DE30d3e52a381b615f01377f7357E7447e2DAD5",
 "0x670FA3a02c6d034372D6d09ABFaB095d3e576dD4",
 "0x9231bB34bc0a7c9bFd33C5456f8ed78e1B64d93A",
 "0x6B19203d70B627151aAFe9134b1Dd98C80aDEf77",
 "0xD2b19fdA48871E4fE48eA66663902c224b74CDa3",
 "0x0c34BA3C34649aBa7C23cF5D158D946C027C2791",
 "0x9f1CbAc7685F5fCC3F05C3471CD7204D19e39eC1",
 "0xa178682201119217D88cdEe77D8651e6b82075a5",
 "0x74ae37B023f411ff80f3a98D1F4680F38F029e57",
 "0xA95d75CF861DEE03fdE05D7B0855a189657F60a3",
 "0x5Be641Bb798c98b1a4F3049fB45057CFC1E8D480",
 "0x5459443f88fDeE4FB3578B3bBfd96aFE41ccd634",
 "0x057C0033633bF9C150F7949DA25A46c0a4b22aF2",
 "0x559Ff60Af5df98F7ac0F4785dd7c0308d5a25fB8",
 "0x3f3538a3d2B10582F47343D1ab5A7cBdA25FcC80",
 "0x67D66F73045FEc590d812507C0652d9d23037b77",
 "0x4f209184399BFC7E00f72D43B3C2600A0e46C7F1",
 "0xcc085813aF7AA5eeff99f2255E6F451140579176",
 "0x3A7dC1A27EC5ca9437c26F098aD6C08598A88b4E",
 "0x02F73B8e29dD6Ec38Bc5D8B3826051DC562c3060",
 "0x99C776201b1e3298c736f603315B29119dE48898",
 "0x731df231ac5B7FB3906D09C8a690996311A5081C",
 "0x34E63550284a4918B9A2e4999B5532d4458B78cD",
 "0x046f34d513915aA9d4e7fbf8a81987E30af21638",
 "0x968798cFa0d8edCE29513dE17780A19d08944942",
 "0x2D9db0402A3F26fe13751427541bF701131aa6AC",
 "0x13CeCacDd47016b7583EF277A245474Bd543E7dF",
 "0x938b62CCFF8e14a4B2CE0f09a9E6A306eF2Be12a",
 "0x321Bc3B3213FedB148A536784350F87E18848b79",
 "0x3C900d80306d0f8906F57d739069b1dC51169dDE",
 "0xe3527334a0139b6c3C65aeB726654FD592900D75",
 "0x6D3343FF9B4a8d4ba872D37bd581660174564978",
 "0xDa8102f2504D4783192a5beA420d90BDEc943F8E",
 "0xd4aE60d4aF6dEc4de59bf5AC280355060871Dc79",
 "0xb2042d811e7F2627DFF9D64beE2695aEeF071674",
 "0xDfAaeC16aA49Ae413DCa692b504B08c70765d1EC",
 "0xC0E028F5b82507479390E888530adA91b5b2Fac7",
 "0x9c95bfBc5D8E04b33659336E7db4AE6c6C56Db70",
 "0x8EDeAe4e41CeA245C2E4FaAfe490ec9cc1Cae9C4",
 "0x25d620ae7847Dbb7ceda8bd562Bf3Cb3f05a2562",
 "0xdc44976EEaF111A958bdF412F78D3683a9e43F28",
 "0x77A228Bca769f10C8Db3945397b51496614bd1B3",
 "0x1222C2CdEE2558ADDfF353FcbaAf7db5a178C869",
 "0x5eE5d189ceB9fB79A5657a34c3858903Ce0c785e",
 "0x9650fe00d81283dB0Ec4a11Cdf991Dd8b31a6353",
 "0xe0CA9b1bd181e5007Ae58A131049a002877626E7",
 "0x3B94125394b68bEEEFA7777Cd87CF358e5A70d92",
 "0xc31d8BBFF61c4fDCAdA33E0498aAdEDcA416E1CE",
 "0x9493d57A998b3548Fe753A5B427C09D3abcD0830",
 "0xEfC813A0492f5cadA0572957e2F69f8741E9b177",
 "0x6d802f4667Ad169aF7bdd203F79666e10409B050",
 "0xDa83b096f2E2c765259422Fea098aF67d8229938",
 "0x83335138e59b48c8B4CF21dB88058e73Cf690c3C",
 "0xa2bB2105673Ad8deabFE33388BbbF31c0125e04e",
 "0x7A7C49bb855937e2b2DF69352fD883991f15a807",
 "0x7e18D5Ab6c2e07D130d8C9810f90a9e1B64DD525",
 "0x7C4665661D2FC4Cbd46b2E3bdC4e71D62C87A7ea",
 "0xd6afAC28229E50746E3B4439A5d6FEfE9B664703",
 "0x33B395d0a0E7bB0f1b04E84cCaDE9229b4EDFeb3",
 "0x58270e866059Bb2812A886C145f14846f28Ae00E",
 "0xa0f8bd3Ee367EdF7b1079f3dF108A8BC8eD183B9",
 "0xb1f1991dbC9Cc3b0a9e57aEf5D0dc266F3515AcE",
 "0x765dcc392407DeF6F05495cd50B43a12d95A4DDe",
 "0xf6Db6a1BDDa72d2aF67C5ea84BeEc8c607C8fCbC",
 "0xf3E3bD0a6BC04B2fe4E58a9B9164C5C34AC11b98",
 "0x6488A2A4d9d36e15eA12e8866E89d7C09933BD2c",
 "0x57ed3198210AdadE970b180C9BE83c32bf5fe8c2",
 "0x2b305B98cD476bab8A667376f23Dd01A80eEfF8C",
 "0x93FB7Ea7b5D77bF9764c451DD0cB0ae3523629b8",
 "0xB4C93d0A04D4B7966776cC2A0Ba31b2C9Bd40d18",
 "0x3Eb94C45a23e1cC85f8A0f502edc5b66bA30EeBb",
 "0x966D3867ED55092a1cf8dF921f938061b6d64BD2",
 "0x9Bde223DdfE5E8C9aDac7AD6a7b75C9b9e10Fe69",
 "0xD4819BFe6D8ceA91bF7E3d49038fdceA3EfdFB21",
 "0x853f4F3756168718867dDa57d625ce8197899f56",
 "0x8AfcEE41a79541fb220682dbb406f474EC1F22A7",
 "0xFD30aEFCFe158dc4fC46E1CC269cA02227Cc235D",
 "0x040412efCEbADf9DE5394Eda082797aC955894e3",
 "0x988FD8b79c817d0bC21318c73046fD8609F8B2C1",
 "0xCaaE08b0EBAc4554D96bF9cb12f08F345B1223Ca",
 "0x3AB05b82b47222b46618bb245369bfa7959c1938",
 "0x4533E03d983Fae04741131C5f7cB35F1b32A1ACD",
 "0x5D1Ef3d3261cA94e0874057b120Eae7947DeFC10",
 "0xACa9DC103c3A3BDeb3f953dD3b0209a8f41207C5",
 "0xDa8102f2504D4783192a5beA420d90BDEc943F8E",
 "0x053008b9e33e3D3A17856b7773D6EBD2e3E6bC11",
 "0x3532f44472F9269D3284a6b43c4B84844B5461C1",
 "0x0bd347aF80376A0578f2338f0a927733E60DA782",
 "0xfDeFE41924e96Ea9077032351491180BE078Ec59",
 "0x5bbB4891Fd5dA65555ae36f91dc9faf403EC9131",
 "0x8ce4fe19ac5ea0df14200FADd5450c3cF40CF0D0",
 "0x588d988D2C01b5AC3E00044F5Ca4AfD97E6cA9c3",
 "0x8f7E9DB0651A2B420F4bB2c61DA0F0e2BFe91968",
 "0x7e03FF97829c9f5Fe0fb15FD68CCD6F625127078",
 "0x670FA3a02c6d034372D6d09ABFaB095d3e576dD4",
 "0xb495023D8Eb9526D8EC346703f2CFf12F2A6963d",
 "0xA6e950aa70EBaAf99686A5d95aFe8aca8B5E353B",
 "0xdB42c0EB30d82aC837Fd48a8e156Fc2DdaEd629d",
 "0x088444CF5Ad3dCe4e983f85a111876Ca2E3d3097",
 "0xdde6D56D474435E2e23e0a294c0936CAAa8342c8",
 "0x68C733e4e1eAEb06430866aEDB88cA3D92E66d50",
 "0xe723860DFbe37f263329E36a4A017fbd29113F56",
 "0xfF8Ad5e3570Df49D0E18Df7B28e0C1d9475e812e",
 "0x8A70CCcF7A84fBb97E8C9154b43eA96470C6Dc9c",
 "0x082370c203b19508281eb57fBD8b104Cd9A28fbd",
 "0x89AbCD47dA1aDcC90AA02b775c5D720cB3b88cA5",
 "0x5F5f07816F229Fa07f24538f484593C22d4f0bb5",
 "0x55b7942d7bd59547bD42fF23D098F257d0822777",
 "0xC723e062b0c06D258958E532EE363e0C3D81961f",
 "0xA813921e799B3D04677A56791eC08A0D812E80c3",
 "0x44632ff277CB950365D347A8D389EDD41456ffC9",
 "0x82fb1B4dd8129A92558F1964477Dabe1Dd703a37",
 "0x7417363DEdB6a07A93d561E2CDf8D8b8a52c3Cd7",
 "0xC696dA14279A24D3E33eD1ef915Aad573B7C7276",
 "0x482f7098344159ade0B57782c948C4AF5a678cCE",
 "0x155b6485305CcaB44EF7da58AC886C62cE105CF9",
 "0x1287FEF3f1AEE76127FeDE08b3D0d99979Bdbd8f",
 "0x8e6B11dFD79405F19a9aD20b2B91Aa392520B473",
 "0x6dceB23d638922bD261168A1F92676450A28aC14",
 "0xe80F09DDf5e633bb0B59020e82E5cBFD702fe23f",
 "0xbc62Ca626cfA6548D3569250b28BEF52D97300a7",
 "0x78e90B46Ee542441e9FDcD1E072C3BC962fbd62d",
 "0x9B4A3B32DFF61F6Bbfb9fD9f28776376C7a49feB",
 "0x827b121bC7aff8596af6e0fC3aA3B7E18BE14783",
 "0x8b07ABfB1B1abc1e406A1CC3b707041B54a9095B",
 "0x1E7858be756c06EEFE71605b4113cE01aD3533a4",
 "0xc06A54834ed7BBFfddB47005aE84a271fc104bD9",
 "0x2aA7e9659404EFed485711ad5E15D79439Af651c",
 "0xd16323bdEDb461558E70dD7daebCAC605127a22F",
 "0xc2721CebD0774BB084af9195aCF58d33bAD36770",
 "0xeE37f4A24A2b6836f1C296543370b1211BB90B77",
 "0xD7Cb22055715fa039Ec43ea2DF5871108c89bEDf",
 "0x171B64616e29bAc3c85a4909E5d81aD40D1Ccc71",
 "0xc78D826E1C3b52E7d91BcC46ac23537d3FF359A0",
 "0x805953CcF5e21cebB21F645F02FC64F824fB4a71",
 "0x18B9a5eda79371023896cb794Ef0af4Ff4b5031a",
 "0x727b1aFb0Be91241c961aba260f3d8aF6650F277",
 "0xa9EAc9623E3e163f2e455f5e8EB734040A70B08e",
 "0x5bf4cc768aBF549F0F11126159bdbe4D056fB679",
 "0xb7c294829C82Ff8329074c27137137A72Da34B62",
 "0x56Ec6F8Bc159860c698b6De62F6D4f8C1b826A2B",
 "0x417aFF89687Ac8129A8cC2F0A6E3E4E30c92e12d",
 "0xE2Fc618b1973A2E6FAeA551648c17fA5a6954b13",
 "0x91AdF6C2F1645F528af9b0E82b42B9F07ab977b6",
 "0x60a40C31b0d9B0B85428Fcf01D372bED4977F611",
 "0x9Cac725F17d5deB4a65922a6043eAc4081aC6Bc4",
 "0x2BDCEe98064DcEf922C031Ab468a8Cd2CaAe2EE9",
 "0xFB3CB5d97DA5c5da1ec1eCe2b42185b5F94E199B",
 "0x974Ab44B53a46875e4Cf0471FAEBF35b2F9d8561",
 "0xdc0678a3350063e4BdCd13Ecd2b11F7460E57bF8",
 "0x65c5B8FD6c1B116e258aBEa827Ad3E2C9F673d46",
 "0x7D828b25640079d2461Fde495f4b129C86ABce12",
 "0x7D374fe73fD8358a93c53e978477F7Ba4129BE0C",
 "0x7047713cfA7DB79495889AE81C89d4afe22E1e15",
 "0xe12D731750E222eC53b001E00d978901B134CFC9",
 "0x4Fc9CcAd3fEa308242A7000A15DBd2097E084FF8",
 "0xBD84F1c1937c9842E6903AFe0f2E412705cb49D5",
 "0x6DE8b67276a4DaF391f17C31117E6C9E7F10fcd3",
 "0x1639Be60C11a6C8d29e4235CEaE50C192E7633d6",
 "0xf9eC946c7D7Fa6189147EeE1C2B7bF418e7eB705",
 "0x6bC66545D8F8E6bb3B4D62EFd745C9c38A49DAFb",
 "0xFa6875bC5593EC53828C7397e156059bb6Ccb13b",
 "0x4460D957A0Ec58a062C899623e743ba3451ED44C",
 "0xcA8A070396a317cDb61f5a8822E78EA433aD47a2",
 "0x3076CD642C9361392eceE113D6796Ed0f3a5d8cf",
 "0xE9db81A85fDc1f28239bdcA8D3054ad39F44A8D5",
 "0xA67b7E5dC3C821Fd2C209ae1C82Da974cD82dA5E",
 "0x5fFBCB0704aD6b001e7Cd86AF5cd1532e6145047",
 "0x0BdA0F33311E65379461D0A555BbD7669c0eaA22",
 "0xa90a8e7eA5A4280d2Cd8e534E6A33DA1cBd80F48",
 "0xD29993DC51691a242DaA02e3037e522B9b3073eB",
 "0xe2289B87a38D32062a93bD94e9F787F448cAdbb3",
 "0x5DF93A6fa97b02d63bfade0D7e36e881508b6557",
 "0x8450A7852495DaFe063C3E436d0750698777965C",
 "0xcfa8969a17f0f32486f67497E699c0ff4e7F6F6a",
 "0x5aC923882b6d5a23835B101BeB0f3715Cab201e8",
 "0x5A2Be5d7a3EcC2969331D86A3Ecc8d64569E1DD9",
 "0xc6a4Ec874c6c74100d524ca801566641BEfc6d3c",
 "0x80C4e7CAb62d4635aeEd585f0E6FbBD03cBF34E7",
 "0x8ce4fe19ac5ea0df14200FADd5450c3cF40CF0D0",
 "0xA3f6bb2a94349886E52238b027Eec751CfB672f3",
 "0xeF6825Db9133dF0D3E0f4718020AAaF06a60Cb4E",
 "0x582344Bab2f68528b6bF033f5dAA4804ba0019A9",
 "0x20d1110635D4d1Ab8A94Cbb11b6A09cc522361d7",
 "0xB0346A9e750321E0446DF758Be128D63fc3A859b",
 "0xf94134ab839991865a07cad994A6f3e97db3B2a5",
 "0x5971E1B3dba64F04d13d4EfAcFF2F2164AA4b7Cd",
 "0xa178682201119217D88cdEe77D8651e6b82075a5",
 "0x44FF9Fb48523C94e2E60965C6e9869D4d9e5DDf5",
 "0xaC0ccce315A94Ed15f8eE5C5562C51620b3e87E2",
 "0x5a01D7C069c1e490fa6cA1dfF049B800903F0C7f",
 "0xd6bfaCFDC40b8B2Bbf87069592D99Ab99fB85632",
 "0xF31AeC5bDf7b5A7647Cdb08901cA090b700D38F5",
 "0xf37dCcEEF2ADf11C77E7d5C6b980414CC4a56061",
 "0x5a90C6545Ac1af47a7B114244eD79a1C7DCD662B",
 "0x91AdF6C2F1645F528af9b0E82b42B9F07ab977b6",
 "0x4D671255C2dBA4102771c1Ab68274fc5668C7A3E",
 "0xFf87a51506188471EB924F07e662eE8b38866e96",
 "0xb7aD24ef67E01c5a353477Abf81E867cAb48A132",
 "0x44E26E7c6bFC50CD04Fe71376347c6dab2A3b5Db",
 "0xc87EdfeeFB5AF5C802478D81C998f89e8AeFA536",
 "0x8880DCB49b056f6Ac28A64EB292aEE9353b6e2C3",
 "0x2520e4153aD714bf0c8D59aAd1b42dFc6D6a62F3",
 "0xe950842347e4Cb1a9d257ABD9FB8Cd64D360EefC",
 "0x805953CcF5e21cebB21F645F02FC64F824fB4a71",
 "0x49b51147cfE86feA591638C3f249245A543eb48a",
 "0x0d0cdF24a963E5D342afAC51803b5c3454C3B49C",
 "0x6DE45B84402092dBF6dac865Ac33106d41a98708",
 "0x87379c15810e77502e436Ab8f06794cC662D39a5",
 "0x0ae4cC61196507815Eb50e97374539E675200418",
 "0x7B648140248B4B77bb37BcC64F5DCa770B670e05",
 "0x9915eB519fbaAB6BeC191c767a4E230239F95140",
 "0x94A01D4CA7e02aBe5aD6AD25145faEF5d9A554bD",
 "0x22D75AeaE9512aC66358FDa54412903B693942b1",
 "0x3A4f8031A2ECdcC7a6392f792602DC0f69041f31",
 "0x16eB1deaE4C4c32805da237dB93771b4cb12416A",
 "0xBa14cBCf32F81A9dA6269f58b4a278f1F4E78832",
 "0x405a2527b2EC97764D79Fece3fCbaB396155d179",
 "0xAf235758985207c4c7B289B91bFEA12553f2176F",
 "0x69ee43Fd0b773B10A1dca11CC0c4F748219582dc",
 "0xB36689ca158106Dc601cCDCC26AD2592D13E086E",
 "0x2A8e0ebDA46A075D77d197D21292e38D37a20AB3",
 "0x0d2CEE34051B54F92d001037c5190E5f219247Ca",
 "0xF5bF3B7B7D03EfD34BA1E1BcF1694080975d8A1c",
 "0xf35D572b4528d05053aA60ec61C8e60d66FbA782",
 "0x54BA3FF9dDbD2dEE95CDA54e863b07B0cdAD2eE4",
 "0x1eb322c016815EE5b29c071586C1B75Be5934576",
 "0xfE83Fd8a7544B5fc5796E34c80943c4963826D6d",
 "0xD56c4b4f242C36b3BB88E4589294625058f38Df3",
 "0xA1dC437005516418cBeB2aaa015573345F637317",
 "0xB4C93d0A04D4B7966776cC2A0Ba31b2C9Bd40d18",
 "0x3eD08A777a1B087F8bc73Ca94B14Ba64aB8368E2",
 "0x0614B88b47F718aaed6500bA3EC41337fb61FE79",
 "0x8AFe8a855579322aCcbE4656211fF3a739a87095",
 "0x1d0192d26168717780BBD0E1A7690ABF6Efe89F3",
 "0x0aC7aebE9DC301E0a85D12D2B8Cead53bC02Cb26",
 "0x95aa447b1A21843A311b82E8BCC5461b1043f194",
 "0xBD2eAb5074658C0F3FF0eF7045563CafdA008bCB",
 "0x3e522051A9B1958Aa1e828AC24Afba4a551DF37d",
 "0xAB4bFE2c164355b9Fbc3c70Ec35cDC44786484Fb",
 "0x81B1bC0daaBc551FBE495623A42c880403eae573",
 "0xD129b9128457305c35914d9449DcA212F68E3AC0",
 "0xD129b9128457305c35914d9449DcA212F68E3AC0",
 "0xC1D9b5F14ff46a9575D7f95acf5589Fb9E2c58a5",
 "0x20542cA974c0076d381781f5f9A8E7a311948940",
 "0x78e90B46Ee542441e9FDcD1E072C3BC962fbd62d",
 "0x4b5772dcbE389C438A2034531d7B14069C72937D",
 "0xe8dCcc3697E5cAFb6A32830d76F0C8024370C031",
 "0xEE05f233245C33150fd061F732BD213AEE0eC3c7",
 "0xEEfB757B6742d7A1eb7cd32b8A460bDD3012de3b",
 "0xe1B3f8B7710B2251BcB4aC92B31a9F6A3A17e0E8",
 "0x64Ff68fd2770daeC07e7Ea0D2351C4Cc34ca1704",
 "0x348A633BD5Eb306019Ba411b204fFF8e527F824b",
 "0xA218d6A3Ad14CFBFc96017d5d7bcEeE1484BD6A5",
 "0x5ff92581FA55E9F03A53D33fAe494385293515e6",
 "0xb9B03839a6904737AFAfe929097F9158c8463571",
 "0x640EE436595A931Df1162b46E3BF4277b35F0A98",
 "0x1f53d8bd6932c5215F30bB07557277Adc3e49cf2",
 "0xFcf3d0420629Bc4C32bE92E2E9c237432101cC7E",
 "0xB3CD6922415a5C561CDB83a16205A7a403aEDED3",
 "0x8922720485893029a970AEc9063F68e8Edc47AD9",
 "0xeD3892628b2C5ff73E8dc5a7CeDD187EEeFa60a5"
]

export const addresses = whitelist_addresses.map(addr => addr.toUpperCase());
