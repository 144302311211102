import store from '../store';
import axios from 'axios';

const fetchStonksRequest = () => {
   return {
      type: 'CHECK_STONKS_REQUEST',
   };
};

const fetchStonksSuccess = (payload) => {
   return {
      type: 'CHECK_STONKS_SUCCESS',
      payload: payload,
   };
};

const fetchStonksFailed = (payload) => {
   return {
      type: 'CHECK_STONKS_FAILED',
      payload: payload,
   };
};

const connectRequest = () => {
   return {
      type: 'CONNECTION_REQUEST',
   };
};

const fetchDataRequest = () => {
   return {
      type: 'CHECK_DATA_REQUEST',
   };
};

const setFetchDataLoadingFalse = () => {
   return {
      type: 'SET_DATA_LOADING_FALSE',
   };
};

export const fetchMystonks = () => {
   return async (dispatch) => {
      dispatch(fetchDataRequest());
      dispatch(fetchStonksRequest());

      try {
         const userAccount = await store.getState().blockchain.account;

         const walletStonks = await store
            .getState()
            .blockchain.smartContract.methods.walletOfOwner(userAccount)
            .call();

         let stonkIds = walletStonks;
         let numberArray = stonkIds.map(Number);

         const myFetchedNFTList = [];
         await Promise.all(
            numberArray.map(async (id) => {
               const response = await axios.get(
                  `https://thestonksociety.mypinata.cloud/ipfs/QmZgHVfLBCJ9ara92cipJoNycRTtJb3XnZLJUgLVNxbEWC/${id}.json`
               );
               myFetchedNFTList.push(response.data);
            })
         );
         dispatch(fetchStonksSuccess([...myFetchedNFTList]));
      } catch (err) {
         dispatch(
            fetchStonksFailed(
               'Something went wrong. Could not get your stonks.'
            )
         );
      }
   };
};
